import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from '@material-ui/core/styles';
import FiCard from "../components/ficard"
import NewPosts from "../components/newPosts"
import FWCard from "../components/fullWidthCard"
import Counter from "../components/counter";
import FWBanner from "../components/fullWidthBanner";




const counterItems = [{
  label: 'Ülke',
  number: 16
},
{
  label: 'Kedi',
  number: 350000
},
{
  label: 'Köpek',
  number: 175000
},
{
  label: 'Mutlu Ebeveyn',
  number: 480000
},
]

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: theme.spacing(5),

  },
  promoSection: {
    marginTop: theme.spacing(1),
  },
  sectionHeader: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  philosophySection: {
    marginTop: theme.spacing(10),
    backgroundColor: '#EEEEEE',
    padding: '16px'
  },
  differencesSection: {
    backgroundColor: '#EEEEEE',
    marginTop: theme.spacing(10),

  },
  aboutUsSection: {
    display: 'flex',
    marginTop: theme.spacing(1),
    backgroundColor: '#F1F1F1;',
    flexWrap: 'no-wrap'
  },
  sectionWrapper: {
    flexWrap: 'nowrap',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  },
  marginTop: {
    marginTop: theme.spacing(5)
  },
  cardGridWrapper: {
    flex: '1 0 100%',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    justifyContent: 'center',
    gap: '2%',

    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      flexDirection: 'column',
    }
  }






}))

const IndexPage = ({ location }) => {

  const classes = useStyles()
  const locale = 'tr-TR'
  return (
    <Layout nodeLocale={locale} location={location}>
      <SEO
        title="Anasayfa"
        lang={locale}
        location={location}
      />
      <Grid container spacing={0} className={classes.root}>
        <Grid item xs={12}>
          <FWBanner imgName='main-banner-desktop.jpg' />
        </Grid>

        <Grid item xs={12} className={classes.marginTop}>
          <Grid container className={classes.cardGridWrapper}>
            {counterItems.map((item, index) =>
              <Counter key={index} number={item.number} label={item.label} />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={`${classes.sectionWrapper} ${classes.marginTop}`}>
            <FWCard title='Hikayemiz' subtitle="Prochoice, hayvan sevgisi ile bir araya gelmiş üç arkadaşın “kendimizin yemeyeceği hiçbir şeyi hayvanlarımıza yedirmeyeceğiz” misyonu ile büyüyen, bugün 150 kişi, 26 köpek ve 30 kedili kocaman bir aile." actions={[{ to: '/hakkimizda/biz-kimiz/', label: 'Devamı' }]} image='home-about-us-mobile+desktop.jpg' position='right' />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container className={`${classes.sectionWrapper} ${classes.marginTop}`}>
            <FiCard img='home-explore-cat-products-desktop.jpg' title='Kediniz Kendini Bir Gurme Gibi Hissedecek!' subtitle='“Fast Food” tarzında mamalara güle güle deyin. Taze İçerikler ile hazırlanan kedi mamalarımız hem lezzetli hem de sağlıklı. Leziz tariflerimizi inceleyin.' controls={[{ link: '/urunler/kedi/kuru-mamalar/', label: 'Kuru Mamalar' }, { link: '/urunler/kedi/yas-mamalar/', label: 'Yaş Mamalar' }]} left dark />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={`${classes.sectionWrapper} ${classes.marginTop}`}>
            <FWCard title='Kullandığımız İçerikler.' subtitle="Veteriner hekimler ve hayvan beslenme uzmanlarından oluşan ekibimiz, yaptığımız her tarif için en kaliteli, en doğal ve en taze malzemeleri özenle seçer. Kullandığımız ürünlerin tüylü dostlarımız için bir çok faydası var!" actions={[{ to: '/hakkimizda/icerikler/', label: 'İÇERİKLERİ keşfet' }]} image='home-fresh-ingredients.jpg' position='left' />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={`${classes.sectionWrapper} ${classes.marginTop}`}>
            <FWCard title='16 Ülkede Raflardayız' subtitle="Üretimden rafa, mamalarımızı mükemmel lojistik planlama ve süreç yönetimi ile, mümkün olan en hızlı şekilde ulaştırıyoruz. Bu sayede ürünlerimiz, dünyanın neresinde olursa olsun rafta her zaman taze olarak sunuluyor."  image='world-map.jpg' position='right' />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={`${classes.sectionWrapper} ${classes.marginTop}`}>
            <FiCard img='home-explore-dog-products-desktop.jpg' title='İnsanın En İyi Dostu En İyisini Hak Eder.' subtitle="Taze içerikler ile hazırlayıp süper besinler ile destekleyerek tamamladığımız mamalarımız hem lezzetli hem de çok faydalı. Dostunuza bir iyilik yapın ve onu Prochoice ile besleyin. " controls={[{ link: '/urunler/kopek/kuru-mamalar/', label: 'Kuru Mamalar' }, { link: '/urunler/kopek/yas-mamalar/', label: 'Yaş Mamalar' }]} dark />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container className={`${classes.sectionWrapper} ${classes.marginTop}`}>
            <NewPosts locale={locale} />
          </Grid>

        </Grid>

        <Grid item xs={12}>
          <Grid container className={`${classes.sectionWrapper} ${classes.marginTop}`}>
            <FiCard img='main-page-contact-us-desktop.jpg' title='Sorunuz ya da öneriniz mi var?' subtitle="Dostlarımızın ihtiyaçlarını daha iyi anlayabilmemiz, ve onlara yönelik iyileştirmeler yapabilmemiz için sizden gelen her geri dönüş bizler için çok kıymetli." controls={[{ link: '/iletisim/', label: 'İLETİŞİME GEÇ' }]} left dark />
          </Grid>
        </Grid>




      </Grid>


    </Layout>

  )
}


export default IndexPage
